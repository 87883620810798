<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_28_32)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M128 256C198.692 256 256 198.692 256 128C256 57.3075 198.692 0 128 0C57.3075 0 0 57.3075 0 128C0 198.692 57.3075 256 128 256ZM189.705 99.0581C193.603 93.6982 192.418 86.1932 187.058 82.2952C181.698 78.3971 174.193 79.5821 170.295 84.9419L114.555 161.585L84.4853 131.515C79.799 126.828 72.201 126.828 67.5147 131.515C62.8284 136.201 62.8284 143.799 67.5147 148.485L107.515 188.485C109.996 190.967 113.44 192.238 116.939 191.963C120.437 191.689 123.641 189.896 125.705 187.058L189.705 99.0581Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
